import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Input, Col } from "reactstrap";
import "./HrTickets.css";
import MaterialIcon from "material-icons-react";
import TicketList from "./TicketList";
import { useGetSupportTicketAssets } from "./useHrTicketsHooks";

export default function HrTicketList(props) {
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(0);
  const [userType, setUserType] = useState(1);
  const [viewMember, setViewMember] = useState(0);
  const history = useHistory();


  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
      if (login.privileges.includes("access_all_reports")) {
        setUserType(1);
      } else if (login.privileges.includes("access_team_reports")) {
        setUserType(2);
      } else {
        setUserType(3);
      }
    }
  };

  const { data: supportAssets, isLoading, error } = useGetSupportTicketAssets();

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }
  
  useEffect(() => {
    // if (supportAssets) {
    //   setOpenCount(supportAssets.open_count);
    // }
  },[supportAssets]);


  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="support-tickets-list">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem=""
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody PerformanceReportpage">
            {supportAssets?.data && (
              <TicketList assets={supportAssets?.data} />
            )}   
          </div>
          </Row>
        </Container>
    </div>
    );
}