import React, {Component, Fragment} from 'react';
import './LiveUpdatesSideMenu.css';
import Lottie  from 'react-lottie'
// import Icon from '../63-home-solid.json'
import IconOutline from './45-clock-time-outline.json'
import { motion } from "framer-motion"
import { Link} from "react-router-dom";
import MaterialIcon, { Outlined } from 'material-icons-react';
export default class LiveUpdatesSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: this.props.activeitem === 'livedata'? true : false,
        }
    }
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    render() {        

        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: IconOutline,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }

        let menuClass = this.state.menuActive ? 'nsmi_submenu_open' : 'nsmi_submenu_close'

        const list = {
            visible: {
              opacity: 1,
              transition: {
                when: "beforeChildren",
                staggerChildren: 0.01,
                delay: 0.01
              } 
            },
            hidden: { 
              opacity: 0,
              transition: {
                when: "afterChildren",
              } 
            },
          }

          const item = {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -5 },
          }


          return(
            <div
                className={"nsmi_home menu-item-home menu-item "+(this.props.activeitem === 'livedata'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className={"nsmi_menu_wrapper d-flex "+ menuClass} onClick={this.toggleMenu}>
                    <div className="nsmi_menu-icon" >
                        <MaterialIcon icon="tips_and_updates"/>
                    
                        {/* <Lottie 
                            options={defaultOptions}
                            height={35}
                            width={35}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                        /> */}
                    </div>                
                    <p className="nsmi_menu_name">Live Update</p>
                    <div className="menu_icon_arrow ml-auto"></div>
                </div>
                {
                    this.state.menuActive &&
                        (
                            <motion.ul 
                                className="nsmi_submenu " 
                                initial="hidden"
                                animate="visible"
                                variants={list}>
                                 <Fragment>
                                        <Link to="/liveactivity"> <motion.li variants={item}>All Profiles</motion.li></Link>
                                        <Link to="/liveactivity-designers"><motion.li variants={item}>Designers</motion.li></Link>                                       
                                     </Fragment>                                 
                            </motion.ul>
                        )
                }
            </div>
        )
    }
}