import React, {Component, Fragment} from 'react';
import './ApproveSideMenu.css';
import {motion} from 'framer-motion'
import IconOutline from './24-approved-checked-outline.json'
import { Link} from "react-router-dom";
import MaterialIcon  from 'material-icons-react';

export default class ApproveSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: this.props.activeitem === 'approval'? true : false,
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    render() {
        let menuClass = this.state.menuActive ? 'nsmi_submenu_open' : 'nsmi_submenu_close'

        const list = {
            visible: {
              opacity: 1,
              transition: {
                when: "beforeChildren",
                staggerChildren: 0.01,
                delay: 0.01
              } 
            },
            hidden: { 
              opacity: 0,
              transition: {
                when: "afterChildren",
              } 
            },
          }

          const item = {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -5 },
          }

        return(
            <div
                className={"nsmi_home menu-item-home menu-item "+(this.props.activeitem === 'approval'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className={"nsmi_menu_wrapper d-flex "+ menuClass} onClick={this.toggleMenu}>
                    <div className="nsmi_menu-icon" >
                      <MaterialIcon icon="verified"/>
                    </div>                
                    <p className="nsmi_menu_name">Approve</p>
                    <div className="menu_icon_arrow ml-auto"></div>
                </div>
                {
                    this.state.menuActive &&
                        (
                            <motion.ul 
                                className="nsmi_submenu " 
                                initial="hidden"
                                animate="visible"
                                variants={list}>
                                {   (this.props.myprivileges.includes('access_monthly_overview') && this.props.myprivileges.includes('approve_user_requests') ) ?
                                    (<Fragment>
                                        <Link to={`/approve-request?uuid=${this.props.logger}`}  ><motion.li variants={item}>Activity</motion.li></Link>
                                        <Link to={`/admin-notifications?uuid=${this.props.logger}`}><motion.li variants={item}>Notifications</motion.li></Link>
                                     </Fragment>
                                    )
                                    :
                                    (
                                       <Fragment>
                                        <Link to={`/approve-request?uuid=${this.props.logger}`}><motion.li variants={item}>Activity</motion.li></Link>                                        
                                     </Fragment> 
                                    )
                                }
                            </motion.ul>
                        )
                }
            </div>
        )
    }
}