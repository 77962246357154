import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './MemberPagination.css';

const MemberPagination = ({
  currentPage,
  totalPages,
  pageSize,
  totalItems,
  onPageChange,
  maxPagesToShow = 5,
  showPageSizeSelector = true,
  pageSizeOptions = [10, 25, 50, 100],
  showItemsInfo = true
}) => {
  // Calculate page numbers to show
  const getPageNumbers = () => {
    const pages = [];
    
    // Logic to determine which page numbers to show with ellipsis for large ranges
    if (totalPages <= maxPagesToShow) {
      // If we have fewer pages than max, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Calculate start and end of the page range
      const halfMax = Math.floor(maxPagesToShow / 2);
      let startPage = Math.max(currentPage - halfMax, 1);
      let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
      
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(endPage - maxPagesToShow + 1, 1);
      }
      
      // Add first page and ellipsis if needed
      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push('...');
        }
      }
      
      // Add the main range of pages
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      
      // Add ellipsis and last page if needed
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push('...');
        }
        pages.push(totalPages);
      }
    }
    
    return pages;
  };

  // Handle page size change
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    onPageChange(1, newSize); // Reset to first page when changing page size
  };

  // Calculate displayed item range info
  const getItemsInfo = () => {
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(start + pageSize - 1, totalItems);
    return `${start}-${end} of ${totalItems} items`;
  };

  return (
    <div className="custom-pagination-container">
      <div className="pagination-controls">
        <Pagination aria-label="Page navigation">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink first onClick={() => onPageChange(1, pageSize)} />
          </PaginationItem>
          
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink previous onClick={() => onPageChange(currentPage - 1, pageSize)} />
          </PaginationItem>
          
          {getPageNumbers().map((page, index) => (
            <PaginationItem key={index} active={page === currentPage} disabled={page === '...'}>
              <PaginationLink
                onClick={() => page !== '...' ? onPageChange(page, pageSize) : null}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
          
          <PaginationItem disabled={currentPage === totalPages || totalPages === 0}>
            <PaginationLink next onClick={() => onPageChange(currentPage + 1, pageSize)} />
          </PaginationItem>
          
          <PaginationItem disabled={currentPage === totalPages || totalPages === 0}>
            <PaginationLink last onClick={() => onPageChange(totalPages, pageSize)} />
          </PaginationItem>
        </Pagination>
      </div>
      
      <div className="pagination-options">
        {showItemsInfo && totalItems > 0 && (
          <div className="items-info">
            {getItemsInfo()}
          </div>
        )}
        
        {showPageSizeSelector && (
          <div className="page-size-selector">
            <span>Show</span>
            <select 
              value={pageSize} 
              onChange={handlePageSizeChange}
              className="form-control form-control-sm"
            >
              {pageSizeOptions.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
            <span>per page</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default MemberPagination;