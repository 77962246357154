import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import {getThumbnail} from "./getThumbnail";
import { urls } from "../../services/UrlList";
import "./basecampmodule.css";
import Header from "../header/Header";
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import { AccessCheck, AccessCheckBlock } from "./AccessCheck";
import { useHistory } from "react-router";
import AccessDenied from "./AccessDenied";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import toast, { Toaster } from "react-hot-toast";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {useGetPortfolioAssetsReview, usePortfolioAssetsAction } from "./useBoardHooks";
import Lightbox from "react-lightbox-component";
import "react-lightbox-component/build/css/index.css";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import {useCurrentMonth, useCurrentMonthWithFullName} from "./PortfolioAssetFunctions";
import {Spinner} from "reactstrap";
import { handleFileDownload } from "./HandleFileDownload";


export default function PortfolioAssets(props) {
  const [myId, setmyId] = useState(0);
  const [accessdenied, setAccessDenied] = useState(false);
  const [progressbar, setProgressBar] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [opencount, setOpenCount] = useState(0);
  const [approvedFiles, setApprovedFiles] = useState([]);
  const [unApprovedFiles, setUnApprovedFiles] = useState([]);
  const [selectedIndustry, setSelectedIndustry]=useState(0);
  const [selectedMonth, setSelectedMonth] = useState(useCurrentMonth());
  const [selectedMonthLabel, setSelectedMonthLabel] = useState(useCurrentMonthWithFullName());
  const [selectedCom, setSelectedCom]=useState(0);
  const [assetProcessing, setAssetProcessing]= useState(0);
  const [assetProcessingAction, setAssetProcessingAction]= useState(0);
  const taskCategoryRef = useRef(null);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [downloadId, setDownloadId] = useState([]);

  const history = useHistory();

  useEffect(() => {
    checkUserPrivilege();
  }, []);

  useEffect(() => {
    AccessCheck("i_am_portfolio_admin");
  }, []);


  const { isLoading: reviewLoading, data: reviewData } =
  useGetPortfolioAssetsReview(selectedMonth, selectedCom);

  const checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") !== null) {
      setmyId(login.uid);
    }
  };

 
  function toggleSidebar() {
    setShowMenu(!showMenu);
  }

  function hideMenu() {
    setShowMenu(!showMenu);
  }

  function goToHome() {
    //this.props.history.push("/home");
    history.push({ pathname: "/home" });
  }

  function goToProjectsHome() {
    //this.props.history.push("/boards");
    history.push({ pathname: "/boards" });
  }

  function breadNavigation(murl) {
    console.log(murl);
    history.push({ pathname: murl });
  }

  const filterIndustry =(id)=>{
    setSelectedIndustry(id);
  }



  useEffect(() => {
    if (reviewData?.data.files.length > 0) 
    {
      let fileslist = [...reviewData.data.files];
      let tempunappr = [];
      let tempappr = [];
      fileslist.forEach((p1) => 
      {
        if(p1.status === 1 && (selectedIndustry>0 ? p1.industryid === selectedIndustry : 1))
        {
          let temp1 = p1;
          temp1.src = `${urls.cdnURL}${p1.file_location}/${p1.filename}`;
          temp1.sfile = p1;
          temp1.tile = p1.filename;
          temp1.filename = p1.filename;
          temp1.description = p1.filename;
          temp1.author = p1.full_name;
          temp1.submitted_on = p1.submitted_on;
          temp1.industry = p1.industry;
          tempappr.push(temp1);
        }else if(p1.status === 2 && (selectedIndustry>0 ? p1.industryid === selectedIndustry : 1))
        {
          let temp1 = p1;
          temp1.src = `${urls.cdnURL}${p1.file_location}/${p1.filename}`;
          temp1.sfile = p1;
          temp1.tile = p1.filename;
          temp1.filename = p1.filename;
          temp1.description = p1.filename;
          temp1.author = p1.full_name;
          temp1.submitted_on = p1.submitted_on;
          temp1.industry = p1.industry;
          tempunappr.push(temp1);
        }
        
      }
    );
    setApprovedFiles(tempappr);
    setUnApprovedFiles(tempunappr);
   }
  }, [reviewData]);

  const filterByIndustry =(id)=>
  {
    setSelectedIndustry(id);
    let fileslist = [...reviewData.data.files];
    let tempunappr = [];
    let tempappr = [];
    fileslist.forEach((p1) => 
    {
      if(p1.status === 1 && (id>0 ? p1.industryid === id : 1) )
      {
        let temp1 = p1;
        temp1.src = `${urls.cdnURL}${p1.file_location}/${p1.filename}`;
        temp1.sfile = p1;
        temp1.tile = p1.filename;
        temp1.filename = p1.filename;
        temp1.description = p1.filename;
        temp1.author = p1.full_name;
        temp1.submitted_on = p1.submitted_on;
        temp1.industry = p1.industry;
        tempappr.push(temp1);
      }else if(p1.status === 2 &&  (id>0 ? p1.industryid === id : 1) )
      {
        let temp1 = p1;
        temp1.src = `${urls.cdnURL}${p1.file_location}/${p1.filename}`;
        temp1.sfile = p1;
        temp1.tile = p1.filename;
        temp1.filename = p1.filename;
        temp1.description = p1.filename;
        temp1.author = p1.full_name;
        temp1.submitted_on = p1.submitted_on;
        temp1.industry = p1.industry;
        tempunappr.push(temp1);
      }
    }
  );
  setApprovedFiles(tempappr);
  setUnApprovedFiles(tempunappr);
  }

  const handlePortfolioAction = (asset_id, type, ) => {
    setAssetProcessing(asset_id);
    setAssetProcessingAction(type);
    const data1 = {
      asset_id,
      type,
      month: selectedMonth
    };
    console.log(data1);
    portfolioActionData(data1);
  };
  const { mutate: portfolioActionData, isLoading: mutateLoading, } = usePortfolioAssetsAction();

  const trigerHandleAssetDownload = async (path, filename) =>
    {
        setDownloadProgress(true);
        let tempdowmloadids = [...downloadId];
        tempdowmloadids.push(filename);
        setDownloadId(tempdowmloadids);
        let downloadedfile = await  handleFileDownload(path, filename);
        let itemkey = tempdowmloadids.map(function (item) { return item; }).indexOf(downloadedfile); 
        tempdowmloadids.splice(itemkey, 1);
        setDownloadId(tempdowmloadids);
        if(tempdowmloadids.length<=0)
        {
            setDownloadProgress(false);
        }
    }

  return (
    <Fragment>
      <div className="fullwidth_mobilemenu">
        <Container fluid className="top-bar-inactive bc_module_container">
          <Row>
            <Toaster
              position="top-right"
              containerClassName="notifier"
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },
              }}
            />
            <div className="theme_header">
              <Header
                ncount={opencount}
                {...props}
                onmenuaction={toggleSidebar.bind(this)}
                className="tick-timer-header"
                quickactionblock={false}
              />
               <SideMenu  
                activeitem="portfolio" 
                showmenu={showMenu} 
                hidemenu={hideMenu.bind(this)}  /> 
            </div>
            <div className="themeContentBody themefullwidthContentBoday">
              <LoadingWrapper isLoading={progressbar}>
                {accessdenied ? (
                  <>
                    <AccessDenied
                      gotohome={goToHome}
                      gotoprojectshome={goToProjectsHome}
                    />
                  </>
                ) : (
                  <>
                    <Col sm="12">
                      <div className="bc_Board_wrapper">
                        <h2 className="section_title subtitle">
                          Graphics Portfolio Assets
                        </h2>
                        <div className="page_header_wrap">
                          <Row>
                            <Col sm={6}><h6>{selectedMonthLabel}</h6></Col>
                            <Col sm={6}>
                            <div className="search-box asset-filter-box">
                               <div className="approved-serach-group">
                                  <Typeahead
                                    className="seacrhfilter"
                                    id="com-picker"
                                    labelKey="full_name"
                                    options={reviewData?.data.coms}
                                    placeholder="COM"
                                    selected=""
                                    onChange={(selected) => {
                                      if (
                                        typeof selected[0] !== "undefined" &&
                                        selected[0] != ""
                                      ) {
                                        setSelectedCom(selected[0].user_id);
                                      }else
                                      {
                                        setSelectedCom(0);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="approved-serach-group">
                                  <Typeahead
                                    ref={taskCategoryRef}
                                    className="seacrhfilter"
                                    id="user-picker"
                                    labelKey="name"
                                    options={reviewData?.data.months.reverse()}
                                    placeholder="Month"
                                    selected=""
                                    onChange={(selected) => {
                                      if (
                                        typeof selected[0] !== "undefined" &&
                                        selected[0] != ""
                                      ) {
                                        console.log(selected[0].id);
                                        setSelectedMonth(selected[0].id);
                                        setSelectedMonthLabel(selected[0].name);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col>
                    {reviewData?.data.industries.length > 0  && (
                        <div className="industry-items">
                            <span className={selectedIndustry===0 && 'active-industry'} 
                            onClick={(e) =>filterByIndustry(0)}
                            >All</span>
                            {reviewData?.data.industries.map((industry, index)=>
                            {
                              return(
                                <span className={selectedIndustry === industry.id && 'active-industry'} 
                                key={industry.id}
                                onClick={(e) =>filterByIndustry(industry.id)}
                                >{industry.industry}
                                </span>
                              )
                            })}
                        </div>
                    )}
                    </Col>
                    <Col>
                      <div className="board-approved-files portfolio-files-list">
                      <h6>Approved Files</h6>
                      {reviewData?.data.files.length>0  && approvedFiles?.length>0  
                       ? (
                            <Lightbox
                              images={approvedFiles}
                              renderImageFunc={(
                                idx,
                                image,
                                toggleLightbox,
                                width,
                                height
                              ) => {
                                return (
                                  <div className="board-approved-files-list  d-flex">
                                    <div>
                                        <img
                                        key={idx}
                                        //src={`${urls.s3Url}${image.file_location}/${image.filename}`}
                                        //src={getThumbnail(`${urls.s3Url}${image.file_location}/${image.filename}`, 'small',  true)}
                                        src={`${urls.cdnURL}${image.file_location}/${image.filename}?class=small`}
                                        className="img-circle"
                                        style={{ width: '100px', height: '100px' }}
                                        onClick={toggleLightbox.bind(null, idx)}
                                        />
                                        {(AccessCheckBlock('i_am_portfolio_admin') || AccessCheckBlock('i_am_admin') || AccessCheckBlock('i_am_pl') || AccessCheckBlock('i_am_operations')) &&
                                        (
                                        <Button className="approve-reject-btns approve" 
                                            onClick={(e) =>{ e.preventDefault(); trigerHandleAssetDownload(urls.s3Url+image.file_location+"/"+image.filename, image.filename) }}
                                            > {downloadProgress && downloadId.includes(image.filename) ? <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/> : 'Download'}
                                        </Button>
                                        )}
                                    </div>
                                    <div className="file-info">
                                      <span
                                        className="filename"
                                        id={"tools" + idx}
                                        href="#"
                                      >
                                        {image.filename}
                                      </span>
                                      <span className="taskname taskname-stripped"
                                       id={"taskname" + idx} href="#">
                                        {image.task_name}
                                      </span>
                                      <span className="taskname taskname-stripped">
                                        {image.industry}
                                      </span>
                                      <span className="d-flex">
                                        <span className="">
                                          Posted: {image.full_name}
                                        </span>
                                        <span className="assets-seperator"> | </span>
                                        {image.submitted_on &&
                                        (
                                          <span>
                                           {moment(image.submitted_on, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}
                                          </span>
                                        )}
                                      </span>
                                      <span className="d-flex">
                                        <span className="d-flex">
                                          Approved: {image.comi ? image.comi.split(' ')[0] : ''}
                                        </span>
                                        {image.reviewed_on!=null &&
                                        (
                                          <>
                                            <span className="assets-seperator"> | </span>
                                            <span>
                                              {moment(image.reviewed_on, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"tools" + idx}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}
                                      >
                                        {image.filename}
                                      </UncontrolledTooltip>
                                      
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"taskname" + idx}
                                        style={{
                                          maxWidth: "600px",
                                          width: "auto",
                                        }}
                                      >
                                        {image.task_name}
                                      </UncontrolledTooltip>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                        ):
                        (
                          <span className="no-match">{reviewLoading? 'Loading...' : 'No records found matching the selection'}</span>
                        )}
                        </div>
                    </Col>
                  </>
                )}
              </LoadingWrapper>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
