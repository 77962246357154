import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { urls } from "../../services/UrlList";
import axios from 'axios';

// fetchings
const paymentUsers = async (selectedMonth) => {
    return await axios.post(urls.getMontlyPayUsers, {'selectedmonth': selectedMonth});
}

const paymentInfoSoft = async (data) => {
    return await axios.post(urls.getMontlyPayUserInfoSoft, {'data': data, 'user_id': data.user_id, 'monthselected': data.monthselected});
}

const paymentInfoDeep = async (data) => {
    return await axios.post(urls.getMontlyPayUserInfoDeep, data);
}

const getSalaryMonths = async() => {
    return await axios.get(urls.getSalaryMonths);
}

const getExtraPayments = async()=>
{
    return await axios.get(urls.getExtraPayments);
}

const addUpdateExtraPayments = async (data) => {
    return await axios.post(urls.addUpdateExtraPayments, data);
}

const getPaymentKimpMembers = async(user_type) =>
{
    return await axios.post(urls.getPaymentKimpMembers, {'user_type': user_type});
}

const deleteExtraPayment = async(id) =>
{
    return await axios.post(urls.deleteExtraPayment, {'id': id});
}


const getAdvancePayments = async()=>
{
    return await axios.get(urls.getAdvancePayments);
}

const addUpdateAdvancePayments = async (data) => {
    return await axios.post(urls.addUpdateAdvancePayments, data);
}

const deleteAdvancePayment = async(id) =>
{
    return await axios.post(urls.deleteAdvancePayment, {'id': id});
}

const getHoldingPayments = async()=>
{
    return await axios.get(urls.getHoldingPayments);
}

const addUpdateHoldingPayments = async (data) => {
    return await axios.post(urls.addUpdateHoldingPayments, data);
}

const deleteHoldingPayment = async(id) =>
{
    return await axios.post(urls.deleteHoldingPayment, {'id': id});
}

const getMemberBanks = async()=>
{
    return await axios.get(urls.getMemberBankAccounts);
}

const updateBankAccounts = async (data) => {
    return await axios.post(urls.updateBankAccounts, data);
}

const getEmpImportantDates = async()=>
{
    return await axios.get(urls.getEmpImportantDates);
}
  
//hooks

export const useGetEmpImportantDates = () => {
    return useQuery(['employee-dates'], () =>  getEmpImportantDates(),
    {
        staleTime: 3600000,
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const useUpdateBankAccounts = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateBankAccounts, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['member-banks']);
        }
    });
}

export const useGetMemberBanks = () => {
    return useQuery(['member-banks'], () =>  getMemberBanks(),
    {
        staleTime: 0,
        refetchOnMount:true,
        refetchOnWindowFocus:false
    })
}

export const useDeleteHoldingPayment = (id)=>{
    const queryClient = useQueryClient();
    return useMutation(deleteHoldingPayment, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['holding-payemts']);
        }
    });
}


export const useAddUpdateHoldingPayments = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addUpdateHoldingPayments, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['holding-payemts']);
        }
    });
}
export const useGetHoldingPayments = () => {
    return useQuery(['holding-payemts'], () =>  getHoldingPayments(),
    {
        staleTime: 0,
        refetchOnMount:true,
        refetchOnWindowFocus:false
    })
}

export const useDeleteAdvancePayment = (id)=>{
    const queryClient = useQueryClient();
    return useMutation(deleteAdvancePayment, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['advance-payemts']);
        }
    });
}


export const useAddUpdateAdvancePayments = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addUpdateAdvancePayments, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['advance-payemts']);
        }
    });
}
export const useGetAdvancePayments = () => {
    return useQuery(['advance-payemts'], () =>  getAdvancePayments(),
    {
        staleTime: 0,
        refetchOnMount:true,
        refetchOnWindowFocus:false
    })
}


export const useDeleteExtraPayment = (id)=>{
    const queryClient = useQueryClient();
    return useMutation(deleteExtraPayment, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['extra-payemts']);
        }
    });
}

export const useGetPaymentKimpMembers = (user_type) => {
    return useQuery(['payment-kimp-members'], () => getPaymentKimpMembers(user_type), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
}   

export const useAddUpdateExtraPayments = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addUpdateExtraPayments, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['extra-payemts']);
        }
    });
}
export const useGetExtraPayments = () => {
    return useQuery(['extra-payemts'], () =>  getExtraPayments(),
    {
        staleTime: 0,
        refetchOnMount:true,
        refetchOnWindowFocus:false
    })
}

export const usePaymentUsersSoft = (selectedMonth) => {
    return useQuery(['payment-users-soft'], () =>  paymentUsers(selectedMonth),
    {
        staleTime: 0,
        enabled:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const usePaymentUsersDeep= (selectedMonth) => {
    return useQuery(['payment-users-deep'], paymentUsers(selectedMonth),
    {
        enabled:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}


export const useGetPaymentInfoSoft = (user, monthselected) => {
    let data = user;
    data.monthselected = monthselected;
    return useQuery(['payment-info-soft', user.user_id+"-"+monthselected], () =>  paymentInfoSoft(data),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}

export const useGetPaymentInfoDeep = (user, monthselected) => {
    let data = user;
    data.monthselected = monthselected;
    return useQuery(['payment-info-deep', user.user_id+"-"+monthselected], () =>  paymentInfoDeep(data),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false
    })
}


export const useGetSalaryMonths = () => {
    return useQuery(['salary-months'], () =>  getSalaryMonths(),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
        retry: 1
    })
}
