import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

const DeleteConfirmationModal = ({
  isOpen,
  toggle,
  itemToDelete,
  onDelete,
  itemType = "item",
  itemName = "",
  itemValue = "",
  itemValueLabel = ""
}) => {
  const handleConfirmDelete = () => {
    onDelete(itemToDelete);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Confirm Delete
      </ModalHeader>
      <ModalBody>
        {itemToDelete ? (
          <p>
            Are you sure you want to delete this {itemType}
            {itemValue && (
              <>
                {' with '}
                <strong>{itemValueLabel}: {itemValue}</strong>
              </>
            )}
            {itemName && (
              <>
                {' for '}
                <strong>{itemName}</strong>
              </>
            )}?
            <br />
            <span className="text-danger">This action cannot be undone.</span>
          </p>
        ) : (
          <p>Are you sure you want to proceed with deletion?</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="danger" onClick={handleConfirmDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;