import React,{useState, useEffect} from "react";
import {urls} from "../../../services/UrlList";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../../svgicon/AttachmentIcon";
import {Input} from 'reactstrap';

export default function SupportLightboxPop(props)
{
    const [myFiles, setMyFiles] = useState(props.files);
    const [attachments, setAttachments] = useState([]);
    
     useEffect(() => {
        setAttachments(myFiles);
    },[myFiles]);
    return(
        <div className={`support-ticket-assets d-flex ${((props.listtype && props.listtype == 2) || (props.attachmenttexts && props.attachmenttexts.length>0)) && 'split-instructions' }`}>
            <SimpleReactLightbox>
                <SRLWrapper>
                {(attachments || []).map((attachitem, index) => (
                <div className="attch-items" key={index}>
                    <div className="d-flex">
                        {attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                        || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
                        (
                            <div className="attach_img_close">
                                <a className="bg-medium-attachment"  href={attachitem.path+attachitem.file_name} target="_blank">
                                    <img src={attachitem.path+""+attachitem.file_name} alt={attachitem.file_name}   />
                                </a>
                                {props.deleteitem &&  props.deleteitem === 'yes' &&
                                (
                                    <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem, props.arrayname)}></span>
                                )}
                            </div>
                        ):
                        (
                            <div className="attach_img_close">
                                <a href={attachitem.path+attachitem.file_name} target="_blank" className="attachment-bg bg-small attach_other">
                                    <span className="attachment-type">
                                        <AttachmentIcon />
                                        {attachitem.file_name.split('.').pop()}
                                    </span>
                                </a>
                                {props.deleteitem &&  props.deleteitem === 'yes' &&
                                (
                                    <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem, props.arrayname)}></span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                ))}
                </SRLWrapper>
            </SimpleReactLightbox>
    </div>
    )
}
