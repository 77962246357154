import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Input, Col } from "reactstrap";
import "./LoanRepayment.css";
import MaterialIcon from "material-icons-react";
import EmployeeLoanCard from './EmployeeLoanCard';
import { useGetRepaymentSchedule } from './useLoanRepaymentHooks';
import { use } from 'react';
import toast, { Toaster } from 'react-hot-toast'; 

// Main LoanManagement component
const LoanRepayment = (props) => {
 const [userType, setUserType] = useState(1);
 const [showMenu, setshowMenu] = useState(true);
 const [myId, setmyId] = useState(0);
 const [employeesFullData, setEmployeesFullData] = useState([]);
 const [employeesData, setEmployeesData] = useState([]);
 const [loading, setLoading] = useState(true);
 const [opencount, setOpenCount] = useState(0);
 const history = useHistory();
 
  useEffect(() => {
      checkUserPrivilege();
    }, []);
  
    const checkUserPrivilege = () => {
      if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        setmyId(login.uid);
        if(!login.privileges.includes('loan_manager'))
        {
            history.push("/maintenance");
        }
      }
    };
  
    function toggleSidebar() {
      setshowMenu(!showMenu);
    }
  
    function hideMenu() {
      setshowMenu(!showMenu);
    }

  const { data:empData, error, isLoading:empLoading } = useGetRepaymentSchedule();
  
  useEffect(() => {
    if (empData) {
      setEmployeesData(empData?.data.schedule);
      setEmployeesFullData(empData?.data.schedule);
    }
  }, [empData?.data]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="loan-repayment-module">
        <Row>
        <Toaster position="top-right" containerClassName="notifier"
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                    },}}
              />
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="hruser"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody PerformanceReportpage">
                <div className="p-2">
                <h5 className="text-md font-bold mb-6 pb-4">Employee Loan Repayment Scheduler</h5>
                <div className="space-y-4 employee-loan-section">
                    {employeesData.map(employee => (
                    <EmployeeLoanCard key={employee.id} employee={employee} />
                    ))}
                </div>
                </div>

          </div>
        </Row>
        </Container>
        </div>
  );
};

export default LoanRepayment;