
import React, { useState, useEffect, Suspense, lazy } from "react";
import toast, { Toaster } from 'react-hot-toast'; 

const MyToaster = () => {
    return (
        <Toaster position="top-right" containerClassName="notifier"
            toastOptions={{
            className: '',
            duration: 4000,
            style: {
                background: '#363636',
                color: '#fff',
            },}}
        />
    );
} 
export default MyToaster;