import React, { useState, useEffect} from "react";
import Header from '../header/Header';
import SideMenu from '../NewSideMenu/NewSideMenu';
import { Typeahead } from "react-bootstrap-typeahead";
import { 
    Container, 
    Row, 
    Col, 
    Table, 
    Button, 
    Input,
    FormGroup, 
    Label,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card,
    CardBody,
    Form
} from 'reactstrap';
import toast from 'react-hot-toast'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ExtraPayments.css';
import { useGetMemberBanks, useUpdateBankAccounts, useGetPaymentKimpMembers} from "./useQueryHooks";
import "react-datetime/css/react-datetime.css"; 
import DeleteConfirmationModal from './DeleteConfirmationModal';
import MyToaster from './MyToaster';
import MemberPagination from './MemberPagination';

const MemberBankAccounts = (props) => {
    // Existing state variables
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(0);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentPayment, setCurrentPayment] = useState(null);

    const [opencount, setOpenCount] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [myId, setmyId] = useState(0);
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [userType, setUserType] = useState(1);
    const [member, setMember] = useState(0);
    const [selectedCurrencyAdd, setSelectedCurrencyAdd] = useState("");

    // New pagination state variables
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedPayments, setPaginatedPayments] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        checkUserPrivilege();
    }, []);
  
    const checkUserPrivilege = () => {
        if (localStorage.getItem("user") !== null) {
            var login = JSON.parse(localStorage.getItem("user"));
            setmyId(login.uid);
            setMyPrivileges(login.privileges);
            if (login.privileges.includes("access_all_reports")) {
                setUserType(1);
            } else if (login.privileges.includes("access_team_reports")) {
                setUserType(2);
            } else {
                setUserType(3);
            }
        }
    };
    
    const { isLoading: extraPaymentsLoading, data: extraPaymentsData } = useGetMemberBanks();

    useEffect(() => {
        if (!extraPaymentsLoading) {
            if (extraPaymentsData?.data?.payments) {
                setPayments(extraPaymentsData.data.payments);
                setFilteredPayments(extraPaymentsData.data.payments);
            }
        }
    }, [extraPaymentsLoading, extraPaymentsData]);

    // Update pagination whenever filtered payments change
    useEffect(() => {
        if (filteredPayments.length > 0) {
            setTotalPages(Math.ceil(filteredPayments.length / pageSize));
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            setPaginatedPayments(filteredPayments.slice(startIndex, endIndex));
        } else {
            setPaginatedPayments([]);
            setTotalPages(0);
        }
    }, [filteredPayments, currentPage, pageSize]);

    // Handle page change
    const handlePageChange = (page, newPageSize) => {
        const updatedPageSize = newPageSize || pageSize;
        setCurrentPage(page);
        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
        }
    };

    const { isLoading: usersLoading, data: usersData } =
    useGetPaymentKimpMembers(userType);
    
    const handlePeopleChange = (input, e) => {
        console.log("value", input);
    };
    
    const handleChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if (size > 0) {
            setMember(selectedOptions[0].id);
            setFormData({
                ...formData,
                user_id: selectedOptions[0].id,
                selectedUser: selectedOptions,
                currency: selectedOptions[0].currency
            });
            setSelectedCurrencyAdd(selectedOptions[0].currency);
        } else {
            setFormData({
                ...formData,
                user_id: '',
                selectedUser: [],
                currency: ''
            });
            setSelectedCurrencyAdd('');
        }
    };
    
    function toggleSidebar() {
        setshowMenu(!showMenu);
    }
  
    function hideMenu() {
        setshowMenu(!showMenu);
    }

    const [formData, setFormData] = useState({
        user_id: '',
        amount: '',
        currency:'',
        comment: '',
        settled_on: null,
        selectedUser: []
    });

    const handleSearchChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if (size > 0) {
            setSearchTerm(selectedOptions[0].id);
        } else {
            setSearchTerm(0);
        }
        // Reset to first page when searching
        setCurrentPage(1);
    };

    useEffect(() => {
        if (!searchTerm || searchTerm === 0) {
            setFilteredPayments(payments);
        } else {
            const filtered = payments.filter(payment => payment.user_id === searchTerm);
            setFilteredPayments(filtered);
        }
    }, [searchTerm, payments]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'amount' ? parseFloat(value) || '' : value
        });
    };

    const handleEditPayment = (payment) => {
        const selectedUser = usersData?.data.users.find(user => user.id === payment.user_id);
        setCurrentPayment(payment);
        setFormData({
            full_name:payment.full_name,
            user_id: payment.user_id,
            payment_method: payment.payment_method,
            account_number: payment.account_number,
            account_holder: payment.account_holder,
            bank_name: payment.bank_name,
            ifsc_swift_code: payment.ifsc_swift_code,
            branch: payment.branch,
            account_type: payment.account_type,
            amount_currency: payment.amount_currency,
            source_currency: payment.source_currency,
            target_currency: payment.target_currency,
            loan_currency: payment.loan_currency,
            manual_payment: payment.manual_payment
        });
        setEditModalOpen(true);
    };

    const handleSubmitEdit = (e) => {
        e.preventDefault();
        const updatedPayment = formData;
        addUpdatePayment(updatedPayment,
            {
                onSuccess: () => {
                    toast.success('Payment updated successfully');
                }
            }
        );
        setEditModalOpen(false);
    };
    const {mutate: addUpdatePayment}= useUpdateBankAccounts();
   
    return (
        <div className="fullwidth_mobilemenu mobileflex">
            <Container fluid className="support-tickets-list">
                <MyToaster />
                <Row>
                    <div className="theme_header">
                        <Header
                            ncount={opencount}
                            {...props}
                            className="tick-timer-header"
                            quickactionblock={false}
                        />
                    </div>
                    <div className="SideNavbar">
                        <SideMenu
                            activeitem="hruser"
                            showmenu={showMenu}
                            hidemenu={hideMenu}
                        />
                    </div>
                    <div className="themeContentBody extra-holding-payments">
                        <Container fluid>
                            <Row className="mb-4">
                                <Col>
                                    <div className="d-flex one-line-heading justify-content-between align-items-center">
                                        <h5>Member Bank Accounts</h5>
                                        <div className="ml-auto d-flex gap-2 hr-module-user-filter">
                                            {(!usersLoading) && (
                                                <Typeahead
                                                    id="user-picker"
                                                    labelKey="name"
                                                    onInputChange={handlePeopleChange}
                                                    onChange={handleSearchChange}
                                                    options={usersData?.data.users}
                                                    placeholder="Choose member"
                                                    className="head-filter-member-fa"
                                                    selected=""
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {extraPaymentsLoading ? (
                                        <div className="text-center my-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <Card>
                                            <CardBody>
                                                <Table responsive striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Account Num</th>
                                                            <th>Bank</th>
                                                            <th>IFSC Code</th>
                                                            <th>Branch</th>
                                                            <th>Target</th>
                                                            <th>Manual</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paginatedPayments.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="8" className="text-center">No payments found</td>
                                                            </tr>
                                                        ) : (
                                                            paginatedPayments.map(payment => (
                                                                <tr key={payment.id}>
                                                                    <td>{payment.full_name}</td>
                                                                    <td>{payment.account_number}</td>
                                                                    <td>{payment.bank_name}</td>
                                                                    <td>{payment.ifsc_swift_code}</td>
                                                                    <td>{payment.branch}</td>
                                                                    <td>{payment.target_currency}</td>
                                                                    <td>{payment.manual_payment == 1 && 'Yes' }</td>
                                                                    <td>
                                                                        {(payment.processing_status!==1) ? (
                                                                            <span className="d-flex justify-content-space-around">
                                                                                <Button 
                                                                                className="btn-green"
                                                                                size="sm" 
                                                                                onClick={() => handleEditPayment(payment)}
                                                                                >
                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                                </Button>
                                                                            </span>
                                                                            ):
                                                                            (
                                                                                <span className="d-flex justify-content-center align-itmems-center">-</span>
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </Table>
                                                
                                                {/* Pagination Component */}
                                                {filteredPayments.length > 0 && (
                                                    <MemberPagination
                                                        currentPage={currentPage}
                                                        totalPages={totalPages}
                                                        pageSize={pageSize}
                                                        totalItems={filteredPayments.length}
                                                        onPageChange={handlePageChange}
                                                        maxPagesToShow={5}
                                                        showPageSizeSelector={true}
                                                        pageSizeOptions={[10, 25, 50, 100]}
                                                        showItemsInfo={true}
                                                    />
                                                )}
                                            </CardBody>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} className="bank-account-modal">
                                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>
                                    Edit Bank Account
                                </ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={handleSubmitEdit} className="bank-form">
                                        <div className="form-row d-flex align-items-top justify-content-between">
                                            <span className="account-holder-full-name">
                                                {formData.full_name}
                                            </span>
                                            <div className="payment-method-selector">
                                            <div className="payment-option">
                                                <input 
                                                    type="radio" 
                                                    name="payment_method" 
                                                    id="bankAccount" 
                                                    checked={formData.payment_method === 'bank-account'}
                                                    onChange={e => setFormData({...formData, payment_method: 'bank-account'})}
                                                />
                                                <label htmlFor="bankAccount">
                                                    <span className="circle-indicator"></span>
                                                    <span>Bank Account</span>
                                                </label>
                                            </div>
                                            <div className="payment-option">
                                                <input 
                                                    type="radio" 
                                                    name="payment_method" 
                                                    id="paypal" 
                                                    checked={formData.payment_method === 'paypal'}
                                                    onChange={e => setFormData({...formData, payment_method: 'paypal'})}
                                                />
                                                <label htmlFor="paypal">
                                                    <span className="circle-indicator"></span>
                                                    <span>Paypal</span>
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-field">
                                                <label htmlFor="account_holder">Account Holder Name</label>
                                                <input 
                                                    type="text" 
                                                    id="account_holder" 
                                                    name="account_holder" 
                                                    className="form-input"
                                                    placeholder="Account Holder Name"
                                                    value={formData.account_holder || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="bank_name">Bank Name</label>
                                                <input 
                                                    type="text" 
                                                    id="bank_name" 
                                                    name="bank_name" 
                                                    className="form-input"
                                                    placeholder="Bank Name"
                                                    value={formData.bank_name || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-field">
                                                <label htmlFor="account_number">Account Number</label>
                                                <input 
                                                    type="text" 
                                                    id="account_number" 
                                                    name="account_number" 
                                                    className="form-input"
                                                    placeholder="Account Number"
                                                    value={formData.account_number || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="ifsc_swift_code">IFSC Code / SWIFT Code / Bank Code</label>
                                                <input 
                                                    type="text" 
                                                    id="ifsc_swift_code" 
                                                    name="ifsc_swift_code" 
                                                    className="form-input"
                                                    placeholder="IFSC/SWIFT/Bank Code"
                                                    value={formData.ifsc_swift_code || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-field">
                                                <label htmlFor="branch">Branch / Bank Code</label>
                                                <input 
                                                    type="text" 
                                                    id="branch" 
                                                    name="branch" 
                                                    className="form-input"
                                                    placeholder="Branch/Bank Code"
                                                    value={formData.branch || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="account_type">Account Type</label>
                                                <div className="account-type-options">
                                                    <div className="account-type-option">
                                                        <input 
                                                            type="radio" 
                                                            name="account_type" 
                                                            id="checking" 
                                                            value="checking"
                                                            checked={formData.account_type === 'checking'}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label htmlFor="checking">
                                                            <span className="circle-indicator"></span>
                                                            <span>Checking / Current</span>
                                                        </label>
                                                    </div>
                                                    <div className="account-type-option">
                                                        <input 
                                                            type="radio" 
                                                            name="account_type" 
                                                            id="savings" 
                                                            value="savings"
                                                            checked={formData.account_type === 'savings'}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label htmlFor="savings">
                                                            <span className="circle-indicator"></span>
                                                            <span>Savings</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row currency-row">
                                            <div className="form-field">
                                                <label htmlFor="amount_currency">Amount Currency</label>
                                                <select 
                                                    id="amount_currency" 
                                                    name="amount_currency" 
                                                    className="form-select"
                                                    value={formData.amount_currency || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="EUR">LKR</option>
                                                </select>
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="source_currency">Source Currency</label>
                                                <select 
                                                    id="source_currency" 
                                                    name="source_currency" 
                                                    className="form-select"
                                                    value={formData.source_currency || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="LKR">LKR</option>
                                                </select>
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="target_currency">Target Currency</label>
                                                <select 
                                                    id="target_currency" 
                                                    name="target_currency" 
                                                    className="form-select"
                                                    value={formData.target_currency || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="LKR">LKR</option>
                                                </select>
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="loan_currency">Loan Currency</label>
                                                <select 
                                                    id="loan_currency" 
                                                    name="loan_currency" 
                                                    className="form-select"
                                                    value={formData.loan_currency || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="LKR">LKR</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="manual-payment-option">
                                            <input 
                                                type="checkbox" 
                                                id="manual_payment" 
                                                name="manual_payment"
                                                checked={formData.manual_payment==1 || false}
                                                onChange={e => setFormData({...formData, manual_payment: e.target.checked})}
                                            />
                                            <label htmlFor="manual_payment">Manual Payment</label>
                                        </div>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => setEditModalOpen(false)} className="btn-cancel">
                                        Cancel
                                    </Button>
                                    <Button color="primary" onClick={handleSubmitEdit} className="btn-save">
                                        Save Changes
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Container>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default MemberBankAccounts;