import React, {Component, Fragment} from 'react';
import './ColleaguesSideMenu.css';
import {motion} from 'framer-motion'
import { Link} from "react-router-dom";
import MaterialIcon from 'material-icons-react';
export default class ColleaguesSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: this.props.activeitem === 'kimpteam'? true : false,
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    render() { 
        let menuClass = this.state.menuActive ? 'nsmi_submenu_open' : 'nsmi_submenu_close'

        const list = {
            visible: {
              opacity: 1,
              transition: {
                when: "beforeChildren",
                staggerChildren: 0.01,
                delay: 0.01
              } 
            },
            hidden: { 
              opacity: 0,
              transition: {
                when: "afterChildren",
              } 
            },
          }

          const item = {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -5 },
          }

        return(
            <div
                className={"nsmi_home menu-item-home menu-item "+(this.props.activeitem === 'kimpteam'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className={"nsmi_menu_wrapper d-flex "+ menuClass} onClick={this.toggleMenu}>
                    <div className="nsmi_menu-icon" >
                        <MaterialIcon icon="workspaces"/>
                    </div>                
                    <p className="nsmi_menu_name">Kimp Team</p>
                    <div className="menu_icon_arrow ml-auto"></div>
                </div>
                {
                    this.state.menuActive &&
                        (
                            <motion.ul 
                                className="nsmi_submenu " 
                                initial="hidden"
                                animate="visible"
                                variants={list}>
                                <Fragment>
                                    <Link to={`/users/team-view?uuid=${this.props.logger}`} ><motion.li variants={item}>Members</motion.li></Link>
                                    {(this.props.myprivileges.includes('i_am_admin') || this.props.myprivileges.includes('i_am_pl') 
                                    || this.props.myprivileges.includes('i_am_pm') || this.props.myprivileges.includes('i_am_hradmin') ||
                                    this.props.myprivileges.includes('i_am_com')) &&
                                    (
                                        <Link to={`/colleagues/task?uuid=${this.props.logger}`}><motion.li variants={item}>Teammate Timer</motion.li></Link>
                                    )}
                                </Fragment>
                            </motion.ul>
                        )
                }
            </div>
        )
    }
}