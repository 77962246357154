import { create } from "filepond";

const baseURL = process.env.REACT_APP_BASEURL;
const baseStorageURL = "https://kimphub.com/service/";
const basenotificationUrl = "https://kimphub.com";
const s3Url="https://kimphub-files.s3.amazonaws.com/"; 
const domainURl="https://kimphub.com";
const domain360 = "https://api.kimp360.com/";
const rootdomain360 = "https://www.kimp360.com/";
const cdnUrl = "https://hub-storage.b-cdn.net/";


// const baseURL = "https://kimp.co/service/hwin/";
// const baseStorageURL = "https://kimp.co/service/"; 
// const basenotificationUrl = "http://localhost:3000";   
// const s3Url="https://hubstaging.s3.ap-south-1.amazonaws.com/"; 
// const domainURl="https://kimp.co";
// const domain360 = "https://kstage.kimp.kim/";
// const rootdomain360 = "https://kimp.kim/";


var urls = {
  apiURL: baseURL,
  notificationUrl: process.env.REACT_APP_NOTIFICATIONURL, 
  storageUrl: process.env.REACT_APP_BASESTORAGEURL,

  s3Url: process.env.REACT_APP_S3URL,
  s3OldUrl: process.env.REACT_APP_S3OLDURL,

  s3UrlWsl: process.env.REACT_APP_S3URL_WSL,
  s3OldUrlWsl: process.env.REACT_APP_S3OLDURL_WSL,

  cdnURL:process.env.REACT_APP_CDNURL,
  cdnURLWsl:process.env.REACT_APP_CDNURL_WSL,

  domainURL: process.env.REACT_APP_DOMAINURL,
  domain360: process.env.REACT_APP_DOMAIN360API,
  rootdomain360: process.env.REACT_APP_DOMAIN360,
  sourcedomain360: process.env.REACT_APP_SOURCEDOMAIN360,

  fireapiKey: process.env.REACT_APP_FIREBASEAPIKEY,
  fireauthDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
  fireprojectId: process.env.REACT_APP_FIREBASEPROJECTID,
  firestorageBucket: process.env.REACT_APP_FIREBASEBUCKET,
  firemessagingSenderId: process.env.REACT_APP_FIREBASESENDERID,
  fireappId: process.env.REACT_APP_FIREBASEAPPID,
  firevapidKey: process.env.REACT_APP_FIREBASE_WEBPUSH,
  
  logmein: baseURL +"authorize",
  logmeout: baseURL+"slogout",
  startShift: baseURL +"startmshift",
  endShift: baseURL +"endmshift",
  startBreak: baseURL +"startmbreak",
  endBreak: baseURL +"endmbreak",
  startLunch: baseURL +"startmlunch",
  endLunch: baseURL +"endmlunch", 
  startExtBreak: baseURL +"startmextbreak",
  sendBreakApproval: baseURL +"monkeyrequestbreak",
  waitingBreakApproval: baseURL +"monkeyrequestwaiting/",
  allMactivities: baseURL + "allmactivities/",
  myLoggedTime:baseURL +"monkeylogedtime",
  mybreakTime:baseURL +"monkeybreaktime/", 
  mylunchTime:baseURL +"monkeylunchtime/",
  lunchactivetime:baseURL +"lunchactivetime",
  breakApprovalRequest: baseURL+"monkeyrequestlist",
  checkShiftEnded:baseURL+"checkshiftend/",
  breakApprove:baseURL+"monkeyapprovebreakrequest",
  sendShiftApproval: baseURL+"monkeyrequestshift",
  shiftRemovalRequest:baseURL+"monkeyshiftunblockrequestlist",
  waitingShiftUnblockApproval: baseURL+"monkeyshiftunblockwaiting/",
  unblockShiftAction:baseURL+"monkeyapproveshiftunlock",
  breakenabler:baseURL+"checkbreakenabler/",
  switchUser:baseURL+"loginwithid/",
  getFullDotoUsers:baseURL+"getfulldotousers",
  getFullDotoUsersList:baseURL+"getfulldotouserslist",
  getFullHolidayList:baseURL+"fullholidaylist",
  
  getMyTasks:baseURL+"mytasks/",
  getMyTasksDesigners:baseURL+"mytasksdesigners/",
  getMyNewTasks:baseURL+"fetchnewtasks/",
  getMyDotoTasks: baseURL+"fetchnewdototasks/",
  startMyTask:baseURL+"startmytask",
  pauseMyTask:baseURL+"pausemytask",
  restartMyTask:baseURL+"restartmytask",
  endMyTask:baseURL+"endmytask",
  endInternalTask:baseURL+"endinternaltask",
  findprojectAssignees:baseURL+"findtaskassignees/",
  postTaskStatus:baseURL+"taskstatusupdate",
  postTaskAssigneeStatus:baseURL+"assigneeupdate",
  getMynewTask:baseURL+"addmytask",
  activityData:baseURL+"liveuserdata",
  activityDataDesigners:baseURL+"liveuserdatadesigners",
  getlogTimerReport:baseURL+"logtimerreport",
  getDotoPeople: baseURL+"getreportpeople",
  getDotoPeopleDailyLog: baseURL+"getreportpeopledailylog",
  getLoanPeople: baseURL+"getloanpeople",
  getMonthlyReport: baseURL+"monthlyreport",
  getBaseProjects:baseURL+"reportallprojects",
  getBaseUsers:baseURL+"reportallbusers",
  postBaseProjectReports:baseURL+"reportprojectwise",
  postBaseProjectReportsRange:baseURL+"reportproject",
  getLeaveCalendar: baseURL+"leavecalendar",
  postLogTimeTaken:baseURL+"baseaddcomment",
  getPlDesigners: baseURL+"pldesigners",

  getWorkHoursDaily: baseURL+'getworkhoursdaily/',
  getMyNotificationsLog: baseURL+"showmynotifications/",
  getLatetimeWeekly: baseURL+"getlatetimeweekly/",
  getLatetimeMonthly:baseURL+"getlatetimemonthly/",
  getOverquotaWeekly:baseURL+"getoverquotaweekly/",
  getOverquotaMonthly:baseURL+"getoverquotamonthly/",
  updateNotificationStatus: baseURL+"updatenotificationstatus",
  getAdminWLateComingNotifications: baseURL+"admingetweeklylate",
  getAdminMLateComingNotifications: baseURL+"admingetmonthlylate",
  getAdminWQuotaNotifications: baseURL+"admingetweeklyquota",
  getAdminMQuotaNotifications: baseURL+"admingetmonthlyquota",
  getAdminWorkHoursNotifications: baseURL+"admingetworkhours",
  resolveNotification: baseURL+"resolvenotification",

  getDailyQuote: baseURL+"getdaysquote",
  getUpcomingBirthdays:baseURL+"upcomingbirthdays",
  getKimpCustomers:baseURL+"getkimpcuscount",
  getAllfeeds:baseURL+"getallfeeds/",
  postFeedComment:baseURL+"postfeedcomment",
  getFeedComment:baseURL+"getfeedcomments/",
  likeDislikeFeed:baseURL+"feedlikedislike",
  addnewfeed: baseURL+"addnewfeed",
  getFeedReview:baseURL+"feedpostedmereview/",
  getOthersFeedReview:baseURL+"feedpostedothersreview/",
  approveFeed: baseURL+"approvefeed",

  getMyLeaves: baseURL+"getmyleaves",
  withdrawLeave: baseURL+"withdrawleave",
  getLeaveBalances: baseURL+"leavebalance/",
  getUpcomingHolidays:baseURL+"upcomingholidays/",
  getMyUpcomingHolidays:baseURL+"myupcomingholidays/",
  getLeaveTypes:baseURL+"leavetypes/",
  postLeaveTypeBalance:baseURL+"balanceleavetype",
  getAllDotoUsers:baseURL+"getalldotousers",
  getDotoReporters:baseURL+"getalldotoreportingofficers",
  postApplyLeave:baseURL+"applyleave",
  getWfhRequests: baseURL+"wfhrequests",
  postManagerApproval: baseURL+"wfhmanagerapproval",
  postAdminApproval: baseURL+"wfhadminapproval",
  

  postManagerApprovalComment: baseURL+"wfhmanagerapprovalcomment",
  postAdminApprovalComment: baseURL+"wfhadminapprovalcomment",

  getLeaveRequests: baseURL+"leaverequests",
  postLeaveManagerApproval: baseURL+"leavemanagerapproval",
  postLeaveAdminApproval: baseURL+"leaveadminapproval",


  postLeaveManagerApprovalComment: baseURL+"leavemanagerapprovalcomment",
  postLeaveAdminApprovalComment: baseURL+"leaveadminapprovalcomment",

  postleavemediaupload: baseURL+"leavemediaupload",

  updateProfilePic:baseURL+"updateprofilepic",
  updateAccount: baseURL+"updatepersonal",
  updatePassword:baseURL+"updatepassword",
  getProfileDetails:baseURL+"getprofiledetails/",
  getUserBreakException: baseURL+"getuserbreakexception/",
  allowUserBreakException: baseURL+"allowuserbreakexception",
  updateProfileSettings: baseURL+"updateprofilesettings",
  

  getCoutDown: baseURL+"countdownv",

  postApplyLoan: baseURL+"loanapply",
  postRepayLoan: baseURL+"loanrepay",
  getLoanSummary: baseURL+"loansummary/",
  getLoanSummaryList: baseURL+"loansummarylist/",
  getMyLoansWaiting: baseURL+"myappliedloans/",
  getAdminLoanSummary: baseURL+"adminloansummary",
  getAdminLoanSummaryList: baseURL+"adminloansummarylist",
  getAllLoansWaiting: baseURL+"allappliedloans",
  postReviewLoan: baseURL+"approverejectloan",
  postReviewRepay: baseURL+"approverejectrepay",


  getTrelloBoardCardById: baseURL + "trelloboardcard/",
  getTrelloBoardCardComments: baseURL + "trellocardcomments/",
  getTrelloBoardCardCheckLists: baseURL + "trellocardchecklist/",
  getTrelloBoardCardAttachments: baseURL + "trellocardattachments/",


  allEntries: baseURL + "gettickentries_custom",
  updateBasecampProject: baseURL + "basecampupdateproject",
  getAdminComments: baseURL + "getentryadmincomments/",
  postAdminComments: baseURL +"saveentryadmincomments",
  resolveComment: baseURL +"resolvecomments",
  tickEntriesCustomPeople: baseURL+"gettickentries_custom_people",
  tickEntriesCustomLocal: baseURL+"gettickentries_custom_local",
  tickUsersCustom: baseURL+"gettickusers_custom",

  finduseforedit: baseURL+"finduserforedit/",
  getAllBaseProfiles:baseURL+"allbaseprofiles",
  getNotificationRoles: baseURL+"notificationcategories",
  getDotoRolesTeams: baseURL+"dotorolesteams",
  getReportingOfficers: baseURL+"showreportingofficers",
  getPMTeamLeads: baseURL+"showpmteamleads",
  getProjectManagers: baseURL+"showprojectmanagers",
  getteamLeads: baseURL+"showteamleads",
  getCurrencies: baseURL+"showcurrencies",
  getTimezones: baseURL+"showtimezones",
  updateUserProfile:baseURL+"updateuserprofile",
  updateBasecampProfile:baseURL+"updatebasecampprofile",
  updateNotificationProfile:baseURL+"updatenotificationprofile",
  updateLeaveProfile:baseURL+"updateleaveprofile",
  updateBankProfile:baseURL+"updatebankprofile",
  addPackRevision:baseURL+"addpackrevision",
  addPrivateNote: baseURL+"addprivatenote",
  getSalaryRevisions:baseURL+"getsalaryrevision/",
  getPrivateNotes:baseURL+"getprivatenote/",
  getUserProfiles:baseURL+"getuserprofiles",
  deleteUserProfile:baseURL+"deleteuser",
  uploadUserFiles: baseURL+"updateuserfiles",
  updateCurrentSalary: baseURL+"updatecurrentsalary",
  getEmployeeTypes: baseURL+"employeetypes",
  getTeamCountries: baseURL+"teamcountries",
  getAllTrelloBoards:baseURL+"gettrelloalldata/1",
  getAllTrelloCollections:baseURL+"allcollections",
  getTrelloSingle:baseURL+"gettrellosingledata",
  getTrelloSingleBell:baseURL+"updatesingleboardstatus",
  getCountries:baseURL+"getcountrylist",
  applyLeaveHoliday:baseURL+"applyleaveholiday",
  getAllEmpLeaves: baseURL+"allempleaves",
  getAllEmpLeavesSingle:baseURL+"allempleavessingle",
  getEmployeeLeaveIssues: baseURL+"leaveissues",
  getEmployeeLeaveIssuesAll: baseURL+"leaveissuesall",
  getMyTrelloCollection:baseURL+"gettrellocollectionname/",
  postAdminMassApproval:baseURL+"leavesamassapprove",
  getProfileaStatus:baseURL+"getprofileastatus/",
  updateProfileStatus:baseURL+"updateprofileastatus",
  getTicketAssets: baseURL+"newticketassets",
  uploadTicketFiles: baseURL+"uploadticketfiles",
  uploadTicketCommentFiles: baseURL+"uploadticketcommentfiles",
  newHubTicket: baseURL+"newhubticket",
  newHubTicketComment: baseURL+"newhubticketcomment",
  getTicket: baseURL+"getticket",
  updateTicketAssignees: baseURL+"updateticketassignee",
  updateTicketParticipant: baseURL+"updateticketparticipant",
  updateTicketStatus:baseURL+"updateticketstatus",
  updateTicketTopic:baseURL+"updatetickettopic",
  updateTicketIssueType:baseURL+"updateticketissuetype",
  
  updateTicketIssueNew: baseURL+"updateticketissuenew",

  updateTicketIssueResponsible:baseURL+"updateticketissueresponsible",
  updateTicketDueDate: baseURL+"updateticketduedate",
  getAllHTickets:baseURL+"getallhbtickets",
  getTicketFilters: baseURL+"ticketfilterassets",
  addTicketParticipant: baseURL+"addparticipant",
  addTicketAssignee: baseURL+"addassignee",
  deleteTicket:baseURL+'deletehticket',
  taskQuickClose:baseURL+"quickclosetask",
  getClientRating:baseURL+"getclientrating",
  updateClientRating:baseURL+"updateclientrating",
  updateClientRatingDelayed:baseURL+"updateclientratingdelayed",
  recordOnlineCheck:baseURL+"recordcheckresponse",
  reportOnlineCheckUsers:baseURL+"getonlinereportusers",
  reportOnlineCheck:baseURL+"getonlinereport",
  updateTags:baseURL+"updatetags",
  getMonthlyReportLog: baseURL+"getprojecttimes",
  postBaseProjectReportsForLogs:baseURL+"reportprojectwiseforlog",
  getMyTasksStaging:baseURL+"mytasksstaging/",
  getTrelloClients: baseURL+"gettrelloclients",
  searchHealthReport: baseURL+"checkhealthreportdata",
  getMyQuestions: baseURL+"getmyquestions",
  postHealthReportData: baseURL+"getclienthealthdata",
  addToProject: baseURL+"grantprojectaccess",
  saveAnswerToDB: baseURL+"saveqranswer",
  getQrMembers: baseURL+"getqrmembers",
  getQrReport:  baseURL+"getqrreport",
  getUserProfilesTeam:baseURL+"getuserprofilesteam",
  getMyTasksRunning:baseURL+"currentrunningtask/",
  endMyColleagueTask:baseURL+"endmycolleaguetask",
  endInternalColleagueTask:baseURL+"endinternalcolleaguetask",
  usertimelog: baseURL+"gettimelog/",
  deleteLogEntry: baseURL+"deletetimelog",
  updateTicketDetails: baseURL+"updateticketdetail",
  saveSpeedTest: baseURL+"savespeedtest",
  showSpeedTest: baseURL+"showspeedtest",
  confirmHoliday: baseURL+"confirmupcomingholiday/",
  updateComment: baseURL+"updateticketcomment",
  getDUserRoles: baseURL+"getuerreoles",
  addNewUser: baseURL+"addnewhuser",
  getClosedTrelloBoards: baseURL+"gettrelloclosedboards",
  getBasecampProject: baseURL+"findbasecampproject",
  unlinkClosedBoard: baseURL+"deleteboardmember/",
  getAllTrelloBoardsQa:baseURL+"gettrelloalldataqa/1",
  getTrelloSingleQa:baseURL+"gettrellosingledataqa",
  getTrelloSingleBellQa:baseURL+"updatesingleboardstatusqa",
  clientWorkReport: baseURL+"clientworkreport",
  getPmPls: baseURL+"getpmpls",
  postBaseProjectReportsForLogsClient:baseURL+"reportprojectwiseforlogclientnew",
  projectLogsRange:baseURL+"projectlogsrange",
  addTicketReminder: baseURL+"ticketreminder",
  quickActionTasks: baseURL+"quickbartasks/",
  overLimitReport: baseURL+"overlimitprojects",
  postBaseProjectReportsForOverLimit:baseURL+"reportprojectwiseforoverlimit",
  updateuserprofileteam: baseURL+"updateuserprofileteam",
  getTrelloSingleHealth: baseURL+"gettrellosingledatahealth",
  addTicketReminderSelected: baseURL+"ticketreminderselected",
  removePendingReminders : baseURL+"removependingreminders",
  teamresources: baseURL+"kteams",
  teamPool: baseURL+"kteamspool",
  teamFileSave:baseURL+"saveteamfile",
  addRemoveTrelloWatch: baseURL+"addremovetrellowatch",
  findClientRatio: baseURL+"boardratiopm",
  findWhiteLabels: baseURL+"findwhitelabels",
  
  /* KZONE */
  addNewKBoard: baseURL+"createnewboard",
  editBoard: baseURL+"updateaboard",
  getMyprojects: baseURL+"getmyboards",
  markProjectFavourite: baseURL+"markprojectfavunfav",
  whiteLabelProjects: baseURL+"updatewhitelabels",
  trialLabelProjects: baseURL+"updatetriallabels",
  deleteKProject: baseURL+"deletekproject",
  getProjectDetails: baseURL+"getprojectdetail",
  getProjectBrands: baseURL+"getprojectbrands", 
  addProjectBrands: baseURL+"addprojectbrands",
  updateProjectBrands: baseURL+"editprojectbrands",
  trashProjectBrand: baseURL+"trashprojectbrand",
  getProjectBrandFolders: baseURL+"getallassetfolders",
  getFullTeams: baseURL+"getfullteams",
  getBandNotes: baseURL+"getbrandnotes",
  postBrandNote: baseURL+"addbrandnote",
  editBrandNote: baseURL+"editbrandnote",
  deleteBrandNote: baseURL+"deletebrandnote",
  uploadBrandAssets: baseURL+"uploadbrandassets",
  getBrandFolderAssets: baseURL+"getuploadedbrandassets",
  deleteBrandAsset: baseURL+"deletebrandasset",
  taskAssets: baseURL+"gettaskassets",
  taskTemplateFields: baseURL+"designtemplatefields",
  uploadProjectMedia: baseURL+"uploadprojectmedia",
  uploadProjectMediaEditor: baseURL+"uploadprojectmediaeditor",
  addNewTask: baseURL+"addnewtask",
  updateClientTask: baseURL+"updateclienttask",
  updateClientTaskAssignees: baseURL+"updateclienttaskassignee",
  updateClientTaskNotifiers: baseURL+"updateclienttasknotifiers",
  updateClientTaskLabels: baseURL+"updateclienttasklabels",
  updateClientTaskPriority: baseURL+"updateclienttaskpriority",
  updateClientTaskBrand: baseURL+"updateclienttaskbrand",
  updateClientTaskDueDate: baseURL+"updateclienttaskduedate",
  updateClientTaskStatus: baseURL+"updateclienttaskstatus",
  getTaskDetails:baseURL+"gettaskdetail",
  projectMentions: baseURL+"availablementions",
  addTaskComment: baseURL+"addtaskcomment",
  getTaskComments: baseURL+"gettaskcomments",
  updateTaskComment: baseURL+"updatetaskcomment",
  getTaskList: baseURL+"gettasklist",
  deleteTask: baseURL+"deletetask",
  searchProjectsUsingKey: baseURL+"searchprojectsusingkey",
  searchTasksUsingKey: baseURL+"searchothersusingkey",
  searchProjectsUsingName: baseURL+"searchprojectsname",
  updateMySocket: baseURL+"savemysocket",
  getMyNotifications: baseURL+"getmynotifications",
  startProjectTask:baseURL+"startprojecttask",
  endProjectTask: baseURL+"endprojecttask",
  getTaskRunningLog: baseURL+"getTaskRunningLog",
  getMyProjectTasks: baseURL+"getallmytasks",
  getProjecTaskaccess: baseURL+"getprojectaskaccess",
  projectTaskLogandReassign: baseURL+"projectTaskLogTimeandReassign",
  getActiveRunningTasks: baseURL+"getactiverunningtasks",
  getTaskAssigneeGroup: baseURL+"taskassigneegroup",
  findTaskAssignedCount: baseURL+"findtaskassignedcount",
  endProjectInternalTask: baseURL+"endprojectinternaltask",
  markNotificationasRead: baseURL+"marknotificationasread",
  markNotificationasUnRead: baseURL+"marknotificationasunread",
  checkOtherRunningTask: baseURL+"checkotherrunningtask",
  savebrandInstructions: baseURL+"savebrandinstructions",
  getBrandFolderInfo: baseURL+"getbrandfolderinfo",
  getBrandInfoTask: baseURL+"getbrandinfotask",
  getUnreadNotifications: baseURL+"getmynotifications/",
  checkAssetExist: baseURL+"checkassetexist",
  updateAssetLinks: baseURL+"addassetlinks",
  addSubFolders: baseURL+"addbrandsubfolders",
  editSubFolder: baseURL+"editsubfolder",
  deleteFolderPlusAssets: baseURL+"deletefolderandassets",
  plateTasks: baseURL+"ontaskplate",
  getTeamMembers: baseURL+"teammembers",
  approveDesign: baseURL+"approvedesign",
  duplicateTask:baseURL+"duplicatetask",
  taskIssueTypes: baseURL+"taskissuestypes",
  addTaskTicket: baseURL+"addtaskticket",
  getTaskTickets: baseURL+"gettasktickets",
  addTaskIssues: baseURL+"addtaskissues",
  getTaskIssueLogs: baseURL+"gettaskissuelogs",
  addNewTaskList: baseURL+"addnewtasklist",
  addNewTaskGroup: baseURL+"addnewtaskgroup",
  getTaskListGroup: baseURL+"gettasklistgroup",
  addNewListTask:baseURL+"addnewlisttask",
  updatetTaskListOrder: baseURL+"updatetasklistorder",
  getReportBoards:baseURL+"reportallboards",
  downloadAllAssets: baseURL+"downloadaszip",
  downloadAllTaskFiles: baseURL+"downloadtasksaszip",
  forceDownloadFile: baseURL+"forcedownloadfile",
  markNotificationAllRead: baseURL+"markallnotificationread",
  getInternalActiveProjects: baseURL+"getinternalprojects",
  copyTaskList: baseURL+"copytasklist",
  getBoardLists: baseURL+"getboardlists",
  copyTaskGroup: baseURL+"copytaskgroup",
  deleteTaskList: baseURL+"deletetasklist",
  deleteTaskGroup: baseURL+"deletetaskgroup",
  cloneBoard: baseURL+"cloneboard", 
  getListGroup: baseURL+"getlistgroup",
  findBoardByName: baseURL+"findboardbyname",
  getTaskIssueLogsIndividual: baseURL+"gettaskissuelogsindividual",
  moveTaskBetween: baseURL+"movetaskbetween",
  nonBrandedBoards: baseURL+"nonbrandedboards",
  followUnfollowTask: baseURL+"followunfollowtask",
  getTaskLogs: baseURL+"gettasklog",
  updateClientTaskTitle: baseURL+"updateclienttasktitle",
  getMomResource: baseURL+"getmeetingresource",
  addMeetingTeams: baseURL+"addmeetingteams",
  getUserNotifications: baseURL+"usernotifications",
  updateAssetNote: baseURL+"addassetsnote",
  getMyBoardNames: baseURL+"getboardnames",
  moveClientTask: baseURL+"moveclienttask",
  getBlockedMembers: baseURL+"userblockcheck",


  getMontlyPayUsers: baseURL+"getmonthlypayusers",
  getMontlyPayUserInfoSoft: baseURL+"checkpresentdayssoft",
  getMontlyPayUserInfoDeep: baseURL+"checkpresentdaysdeep/",
  updateSalaryHrNotes: baseURL+"updatesalaryhrnotes",
  updateSalaryHrFinalized: baseURL+"updatesalaryhrfinalized",
  updateSalaryHrVerification: baseURL+"updatesalaryhrverification",
  getSalaryMonths: baseURL+"getsalarymonths",

  getReportUsers: baseURL+"allreportuers",
  lateComingLog: baseURL+"reportlatecoming",
  issueReportingLog: baseURL+"issuereportinglog",

  addSourceTaskRevsions: baseURL+"createtaskrevisions",
  getAllTaskRevisions: baseURL+"getalltaskrevisions",
  getRevsionSubFolders: baseURL+"getrevsionsubfolders",
  createRevisionFolder:baseURL+"createrevisionfolders",
  createRevisionOtherFolder:baseURL+"createrevisionotherfolders",
  uploadTaskSource: baseURL+"uploadtasksource",
  getTaskSourceFiles: baseURL+"tasksourcefiles",
  removeSourceFileData: baseURL+"deletetasksourcefiles",
  getSourceRevsionBread: baseURL+"tasksourcebreadcrumb",
  deleteSourceRevsion: baseURL+"deletesourceversion",
  deleteSourceVersionFolder: baseURL+"deletesourceversionfolder",
  publishUnpublishtaskSourceRevsion: baseURL+"publishunpublishsource",
  getRevisionShareLink: baseURL+"getrevisionsharelink",
  generateRevisionShareLink: baseURL+"generaterevisionsharelink",
  getFolderShareLink: baseURL+"getfoldersharelink",
  generateFolderShareLink: baseURL+"generatefoldersharelink",
  revisonFolderList: baseURL+"revisonfolderlist",
  revisonFolderMove: baseURL+"revisonfoldermove",
  update360CardInfo: baseURL+"update360cardinfo",
  customerSharedRevision: baseURL+"customersharedrevision",
  customerSharedRevisionFiles: baseURL+"customersharedrevisionfiles",
  clearCardRelation: baseURL+"clearcardrelation",
  getFolderMaster: baseURL+"getmasterfolders",
  approveUnapproveFiles: baseURL+"approvesourcefiles",
  approveUnapproveAllFiles: baseURL+"approvesourceAllfiles",
  rejectUnrejectFiles: baseURL+"rejectsourcefiles",
  rejectAllFiles: baseURL+"rejectsourceallfiles",
  approvedSourceFiles: baseURL+"approvedfiles",
  sourceDownloadAsZip: baseURL+"downloadsourceversionfolder",
  boardActivityLog: baseURL+"boardactivitylog",
  allBoardActivityLog: baseURL+"allboardactivitylog",
  sourcePublishedDownloadAsZip: baseURL+"downloadpublishedfolder",
  getPortfolioTypes: baseURL+"getportfoliotypes",
  portfolioSubmitRequest: baseURL+"portfoliosubmitrequest",
  portfolioAssetsReview: baseURL+"portfolioassetsreview",
  videoPortfolioAssetsReview: baseURL+"videoportfolioassetsreview",
  updateClientTaskIndustry: baseURL+"updateclienttaskindustry",
  updatePortfolioStatus: baseURL+"updateportfoliostatus",
  updateVideoPortfolioStatus: baseURL+"updatevideoportfoliostatus",


  show360Profiles: baseURL+"show360profiles",
  updateKimp360Profile: baseURL+"update360profile",
  revisionandcopy: baseURL+"getrevisionandcopy",
  findChildFolders: baseURL+"findchildfolders",
  gets3presigned: baseURL+"gets3presigned",
  findmyactiveshift: baseURL+"findmyactiveshift",
  getCanvaToken: baseURL+"getcanvatoken",
  fetchmailboxemails: baseURL+"getmailboxemails",
  fetchmailboxemailattachments: baseURL+"getmailboxemailattachments",
  importInternals: baseURL+"importinternals/",
  checkCardRelation: baseURL+"checkcardrelation",
  getBoardCollectionMaster: baseURL+"getboardcollections",
  updateBoardCollections: baseURL+"updateboardcollections",
  getCanvaAccountAssets: baseURL+"canvaaccessassets",
  updateCanvaAccount: baseURL+"updatecanvaaccount",
  getCanvaAccountAssets: baseURL+"canvaaccessassets",
  deleteCanvaAccount: baseURL+"deletecanvaaccount",
  myCanvaAccounts: baseURL+"mycanvaaccounts",
  boardApprovedFiles: baseURL+"boardtaskapproved",
  taskClientRating: baseURL+"gettaskrating",
  updateBestTaskDesigns: baseURL+"updatebesttaskdesigns",
  resetBrandBestDesigns: baseURL+"resetbrandbestdesigns",
  getTaskbestdesignlog: baseURL+"taskbestdesignlog",
  addTaskNote: baseURL+"addtasknotes",
  getTaskPrivateNotes: baseURL+"gettaskprivatenotes",
  deletePrivateNote: baseURL+"deleteprivatenote",
  updatePrivateNote: baseURL+"updateprivatenote",
  getProfileReportTasks: baseURL+"profilereporttasks",
  getProfileReportTasksIssues: baseURL+"profilereporttaskissues",
  getProfileReportTicketRespo: baseURL+"profilereportticketrespo",
  getProfileReportLeaves: baseURL+"profilereportleaves",
  getUserFeedbackData: baseURL+"getuserprofilereport",
  addprofileReport: baseURL+"addprofilereport",
  getProfileReportMembers: baseURL+"getmyreportingpeople",
  getMyPerformanceData: baseURL+"getmyperformancedata",
  getMyIndividualPerformaceRating: baseURL+"getindividualuserratings",
  getMyProfileRealatedCount: baseURL+"profilereportrelatedcounts",
  getKimpMembers: baseURL+"getkimpmembers",
  getProfileTaskRatingsList: baseURL+"gettaskratingslist",
  getAiTestMembers: baseURL+"getaitestmembers",
  addAiTestReport: baseURL+"addaitestreport",
  getAiTestReports: baseURL+"getaitestreports",
  getRatingAssets: baseURL+"getratingassets",
  addUpdateProfileRatingAuthor: baseURL+"addupdateratingauthor",
  addUpdateSkipRating: baseURL+"addupdateskiprating",
  getIssueTrackerSteps: baseURL+"getissuetrackersteps",
  getIssueTimeline: baseURL+"getissuetimeline",
  getSupportTicketAssets:baseURL+"getsupportticketassets",
  createSupportTicket:baseURL+"createsupportticket",
  getSupportTickets:baseURL+"getsupporttickets",
  uploadSupportFiles:baseURL+"uploadsupportfiles",
  getSupportTicket:baseURL+"getsupportticket",
  updateSupportAssignee:baseURL+"updatesupportassignee",
  updateSupportTopic:baseURL+"updatesupporttopic",
  updateSupportPriority:baseURL+"updatesupportpriority",
  updateSupportDue: baseURL+"updatesupportdue",
  updateSupportStatus: baseURL+"updatesupportstatus",
  updateSupportParticipant:baseURL+"updatesupportparticipant",
  addSupportTicketComment:baseURL+"addsupportticketcomment",
  listSupportTicketComments: baseURL+"listsupportticketcomment",
  handleSelfResolvingTicket: baseURL+"handleselfresolvingticket",

  getRepaymentSchedule: baseURL+"getrepaymentschedule",
  updateRepaymentData: baseURL+"updaterepaymentdata",
  getProfileTaskList: baseURL+"getprofiletasklist",
  getPaymentKimpMembers: baseURL+"getpaymentkimpmembers",

  getExtraPayments: baseURL+"listextrapayments",
  addUpdateExtraPayments: baseURL+"addupdateextrapayments",
  deleteExtraPayment: baseURL+"deleteextrapayment",

  getAdvancePayments: baseURL+"listadvancepayments",
  addUpdateAdvancePayments: baseURL+"addupdateadvancepayments",
  deleteAdvancePayment: baseURL+"deleteadvancepayment",

  getHoldingPayments: baseURL+"listholdingpayments",
  addUpdateHoldingPayments: baseURL+"addupdateholdingpayments",
  deleteHoldingPayment: baseURL+"deleteholdingpayment", 

  getMemberBankAccounts: baseURL+"listmemberbanks",
  updateBankAccounts: baseURL+"updatebankaccounts",
  getEmpImportantDates: baseURL+"getempimportantdates",
  
  testRq: baseURL+"testrq"
};
export {
  urls
};
