import React,{useEffect, useState} from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody, 
  Table, 
  Badge 
} from 'reactstrap';
import Header from '../header/Header';
import SideMenu from '../NewSideMenu/NewSideMenu';
import './ExtraPayments.css';
import { useGetEmpImportantDates } from "./useQueryHooks";
import {formatDatesDMMYYYY } from '../Utils/HelperFunctions';

const EmployeeImportantDates = (props) => {
    const [opencount, setOpenCount] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [myId, setmyId] = useState(0);
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [userType, setUserType] = useState(1);
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    useEffect(() => {
            checkUserPrivilege();
        }, []);
      
    const checkUserPrivilege = () => {
        if (localStorage.getItem("user") !== null) {
            var login = JSON.parse(localStorage.getItem("user"));
            setmyId(login.uid);
            setMyPrivileges(login.privileges);
            if (login.privileges.includes("access_all_reports")) {
                setUserType(1);
            } else if (login.privileges.includes("access_team_reports")) {
                setUserType(2);
            } else {
                setUserType(3);
            }
        }
    };

    function toggleSidebar() {
        setshowMenu(!showMenu);
    }
  
    function hideMenu() {
        setshowMenu(!showMenu);
    }

    const { data: empData, isLoading: dataLoading, isError } = useGetEmpImportantDates();

  return (
    <div className="fullwidth_mobilemenu mobileflex">
            <Container fluid className="support-tickets-list">
                <Row>
                    <div className="theme_header">
                        <Header
                            ncount={opencount}
                            {...props}
                            className="tick-timer-header"
                            quickactionblock={false}
                        />
                    </div>
                    <div className="SideNavbar">
                        <SideMenu
                            activeitem="report"
                            showmenu={showMenu}
                            hidemenu={hideMenu}
                        />
                    </div>
                    <div className="themeContentBody extra-holding-payments">
                    <div className="py-4">
                        <h5 className="" style={{marginBottom:'20px'}}>Employee Important Dates - {currentMonth}</h5>
                            {dataLoading ? (
                            <div className="text-center my-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            ) : (
                            <>
                            <Row className="mb-4">
                                <Col sm="6">
                                    <Card className="shadow-sm">
                                        <CardHeader className="bg-color-gray text-white">
                                        <h6 className="mb-0">Birthdays</h6>
                                        </CardHeader>
                                        <CardBody className="p-0">
                                        <div className="table-responsive">
                                            <Table hover bordered className="mb-0">
                                            {empData?.data.birthdays.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" className="text-center">
                                                        No one celebrating birthday this month.
                                                    </td>
                                                </tr>
                                            </tbody>
                                            ):
                                            (    
                                                <>
                                                    <thead className="bg-light">
                                                        <tr>
                                                        <th>Name</th>
                                                        <th>Date of Birth</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {empData?.data.birthdays.map((employee, index) => (
                                                        <tr key={index}>
                                                            <td>{employee.full_name}</td>
                                                            <td>{formatDatesDMMYYYY(employee.date_of_birth)}</td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </>
                                            )}
                                            </Table>
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="6">
                                    <Card className="shadow-sm">
                                        <CardHeader className="bg-color-gray text-white">
                                        <h6 className="mb-0">Work Anniversaries</h6>
                                        </CardHeader>
                                        <CardBody className="p-0">
                                        <div className="table-responsive">
                                            <Table hover bordered className="mb-0">
                                            {empData?.data.anniversary.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="3" className="text-center">
                                                        No one celebrating work anniversary this month.
                                                    </td>
                                                </tr>
                                            </tbody>
                                            ):
                                            (
                                                <>
                                                <thead className="bg-light">
                                                    <tr>
                                                    <th>Name</th>
                                                    <th>Date of Joining</th>
                                                    <th>Years</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {empData?.data.anniversary.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{employee.full_name}</td>
                                                        <td>{formatDatesDMMYYYY(employee.date_of_joining)}</td>
                                                        <td>
                                                        <span className='badge-year-service'>
                                                            {employee.years_of_service}
                                                        </span>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                </>
                                            )}
                                            </Table>
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Card className="shadow-sm">
                                    <CardHeader className="bg-color-gray text-white">
                                    <h6 className="mb-0">New Joinees</h6>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                    <div className="table-responsive">
                                        <Table hover bordered className="mb-0">
                                        {empData?.data.newjoinees.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" className="text-center">
                                                        No new joinees this month 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ):
                                        (
                                            <>
                                                <thead className="bg-light">
                                                    <tr>
                                                    <th>Name</th>
                                                    <th>Date of Joining</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {empData?.data.newjoinees.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{employee.full_name}</td>
                                                        <td>{formatDatesDMMYYYY(employee.date_of_joining)}</td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </>
                                        )}
                                        </Table>
                                    </div>
                                    </CardBody>
                                </Card>
                                </Col>
                            </Row>
                            </>
                        )}
                </div>
            </div>
        </Row>
    </Container>
</div>
);
};

export default EmployeeImportantDates;