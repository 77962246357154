import React, { useState, useEffect} from "react";
import Header from '../header/Header';
import SideMenu from '../NewSideMenu/NewSideMenu';
import { Typeahead } from "react-bootstrap-typeahead";
import { 
    Container, 
    Row, 
    Col, 
    Table, 
    Button, 
    Input,
    FormGroup, 
    Label,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card,
    CardBody,
    Form
  } from 'reactstrap';
import toast  from 'react-hot-toast'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ExtraPayments.css';
import { useGetPaymentKimpMembers, useGetHoldingPayments, useAddUpdateHoldingPayments,
    useDeleteHoldingPayment
 } from "./useQueryHooks";
import Datetime from "react-datetime"; 
import "react-datetime/css/react-datetime.css"; 
import DeleteConfirmationModal from './DeleteConfirmationModal';
import MyToaster from './MyToaster';
import { trimString } from '../Utils/HelperFunctions';

const MemberSalaryHoldings = (props) => {
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentPayment, setCurrentPayment] = useState(null);

    const [opencount, setOpenCount] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [myId, setmyId] = useState(0);
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [userType, setUserType] = useState(1);
    const [member, setMember] = useState(0);
    const [selectedCurrencyAdd, setSelectedCurrencyAdd] = useState("");
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [paymentToDelete, setPaymentToDelete] = useState(null);

  useEffect(() => {
      checkUserPrivilege();
    }, []);
  
    const checkUserPrivilege = () => {
      if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        setmyId(login.uid);
        setMyPrivileges(login.privileges);
        if (login.privileges.includes("access_all_reports")) {
          setUserType(1);
        } else if (login.privileges.includes("access_team_reports")) {
          setUserType(2);
        } else {
          setUserType(3);
        }
      }
    };
    

    const { isLoading: extraPaymentsLoading, data: extraPaymentsData } = useGetHoldingPayments();

    useEffect(() => {
        if (!extraPaymentsLoading) {
            if (extraPaymentsData?.data?.payments) {
                setPayments(extraPaymentsData.data.payments);
                setFilteredPayments(extraPaymentsData.data.payments);
            }
        }
    }, [extraPaymentsLoading, extraPaymentsData]);

    const { isLoading: usersLoading, data: usersData } =
    useGetPaymentKimpMembers(userType);
    
    const handlePeopleChange = (input, e) => {
        console.log("value", input);
    };
    
    const handleChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if (size > 0) {
          setMember(selectedOptions[0].id);
          setFormData({
            ...formData,
            user_id: selectedOptions[0].id,
            selectedUser: selectedOptions,
            currency: selectedOptions[0].currency
          });
          setSelectedCurrencyAdd(selectedOptions[0].currency);
        } else {
          setFormData({
            ...formData,
            user_id: '',
            selectedUser: [],
            currency: ''
          });
          setSelectedCurrencyAdd('');
        }
    };


    const handleAddChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if (size > 0) {
            setMember(selectedOptions[0].id);
            setFormData({
                ...formData,
                user_id: selectedOptions[0].id
            });
            setSelectedCurrencyAdd(selectedOptions[0].currency);
        }else
        {
            setSelectedCurrencyAdd('');
            setMember(0);
        }
    };
    
    function toggleSidebar() {
      setshowMenu(!showMenu);
    }
  
    function hideMenu() {
      setshowMenu(!showMenu);
    }

  const [formData, setFormData] = useState({
    user_id: '',
    amount: '',
    currency:'',
    comment: '',
    settled_on: null,
    selectedUser: []
  });


  const handleSearchChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if (size > 0) {
      setSearchTerm(selectedOptions[0].id);
    } else {
        setSearchTerm(0);
    }
  };

  useEffect(() => {
    if (!searchTerm || searchTerm === 0) {
      setFilteredPayments(payments);
    } else {
      const filtered = payments.filter(payment => payment.user_id === searchTerm);
      setFilteredPayments(filtered);
    }
  }, [searchTerm, payments]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'amount' ? parseFloat(value) || '' : value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      settled_on: date
    });
  };

  const isValidDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current.valueOf() >= today.valueOf();
  };

  const handleAddPayment = () => {
    setFormData({
      user_id: '',
      amount: '',
      currency:'',
      comment: '',
      settled_on: null,
      selectedUser: []
    });
    setDrawerOpen(true);
  };

  const handleEditPayment = (payment) => {
    const selectedUser = usersData?.data.users.find(user => user.id === payment.user_id);
    setCurrentPayment(payment);
    setFormData({
      user_id: payment.user_id,
      amount: payment.amount,
      currency: payment.currency,
      comment: payment.comment || '',
      settled_on: payment.settled_on ? new Date(payment.settled_on) : null,
      selectedUser: selectedUser ? [selectedUser] : []
    });
    setEditModalOpen(true);
  };


  const handleSubmitAdd = (e) => {
    e.preventDefault();
    if (!formData.user_id || !formData.amount) {
      alert('Please fill in required fieldsss');
      return;
    }
    const newPayment = {
      user_id: formData.user_id,
      amount: parseFloat(formData.amount),
      currency: selectedCurrencyAdd,
      settled_on: formData.settled_on ? formData.settled_on.toISOString() : null,
      comment: formData.comment || null
    };
    addUpdatePayment(newPayment);
    setDrawerOpen(false);
  };

  const {mutate: addUpdatePayment}= useAddUpdateHoldingPayments();

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    if (!formData.user_id || !formData.amount) {
      alert('Please fill in required fields');
      return;
    }
    const updatedPayment = {
      ...currentPayment,
      user_id: formData.user_id,
      amount: parseFloat(formData.amount),
      currency: formData.currency,
      settled_on: formData.settled_on ? formData.settled_on.toISOString() : null,
      comment: formData.comment || null
    };
    addUpdatePayment(updatedPayment);
    setEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not settled';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const handleDeletePayment = (payment) => {
    setPaymentToDelete(payment);
    setDeleteModalOpen(true);
  };
  
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    if (deleteModalOpen) {
      setPaymentToDelete(null);
    }
  };
  
  const confirmDeletePayment = (payment) => {
    if (payment) {
      deleteExtraPay(payment.id);
      toast.success('Payment deleted successfully');
    }
  };

  const {mutate: deleteExtraPay}= useDeleteHoldingPayment();

  return (
    <div className="fullwidth_mobilemenu mobileflex">
    <Container fluid className="support-tickets-list">
        <MyToaster />
      <Row>
        <div className="theme_header">
          <Header
            ncount={opencount}
            {...props}
            className="tick-timer-header"
            quickactionblock={false}
          />
        </div>
        <div className="SideNavbar">
          <SideMenu
            activeitem="hruser"
            showmenu={showMenu}
            hidemenu={hideMenu}
          />
        </div>
        <div className="themeContentBody extra-holding-payments">
          <Container fluid>
            <Row className="mb-4">
                <Col>
                <div className="d-flex one-line-heading justify-content-between align-items-center">
                    <h5>Member Salary Holdings</h5>
                    <div className="ml-auto d-flex gap-2 hr-module-user-filter">
                        {(!usersLoading) && (
                        <Typeahead
                            id="user-picker"
                            labelKey="name"
                            onInputChange={handlePeopleChange}
                            onChange={handleSearchChange}
                            options={usersData?.data.users}
                            placeholder="Choose member"
                            className="head-filter-member-fa"
                            selected=""
                        />
                        )}
                        <Button color="primary"  onClick={handleAddPayment} className="add-pay-btn d-flex align-items-center">
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        </Button>
                    </div>
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                {extraPaymentsLoading ? (
                    <div className="text-center my-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </div>
                ) : (
                    <Card>
                    <CardBody>
                        <Table responsive striped>
                        <thead>
                            <tr>
                            <th>Member</th>
                            <th>DOJ</th>
                            <th>Amount</th>
                            <th>Added On</th>
                            <th>Added By</th>
                            <th>Expiry</th>
                            <th>Settlement</th>
                            <th>Comment</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPayments.length === 0 ? (
                            <tr>
                                <td colSpan="8" className="text-center">No payments found</td>
                            </tr>
                            ) : (
                            filteredPayments.map(payment => (
                                <tr key={payment.id}>
                                    <td>{payment.full_name}</td>
                                    <td>{payment.date_of_joining!='0000-00-00' ? formatDate(payment.date_of_joining) : '-' }</td>
                                    <td>{`${payment.currency} ${payment.amount}`}</td>
                                    <td>{formatDate(payment.added_on)}</td>
                                    <td>{payment.added_by}</td>
                                    <td>{payment.last_day_of_service!='0000-00-00' ? formatDate(payment.last_day_of_service) : '-' }</td>
                                    <td>{payment.settled_on !='1970-01-01 05:30:00' ? formatDate(payment.settled_on) : '-'}</td>
                                    <td>{trimString(payment.comment) || '-'}</td>
                                    <td>
                                        {(payment.processing_status!==1) ? (
                                            <span className="d-flex justify-content-space-around">
                                                <Button 
                                                className="btn-green"
                                                size="sm" 
                                                onClick={() => handleEditPayment(payment)}
                                                >
                                                <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Button 
                                                className="btn-red"
                                                size="sm" 
                                                onClick={() => handleDeletePayment(payment)}
                                                >
                                                <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </span>
                                            ):
                                            (
                                                <span className="d-flex justify-content-center align-itmems-center">-</span>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                            )}
                        </tbody>
                        </Table>
                    </CardBody>
                    </Card>
                )}
                </Col>
            </Row>

            <div className={`payment-drawer ${drawerOpen ? 'open' : ''}`}>
                <div className="drawer-header">
                <h5>Add New Payment</h5>
                <Button 
                    color="link" 
                    className="drawer-close" 
                    onClick={() => setDrawerOpen(false)}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
                </div>
                <div className="drawer-body">
                <Form onSubmit={handleSubmitAdd}>
                    <FormGroup>
                    <Label for="user_id">Member</Label>
                    {(!usersLoading) && (
                    <Typeahead
                        id="user-picker"
                        labelKey="name"
                        onInputChange={handlePeopleChange}
                        onChange={handleAddChange}
                        options={usersData?.data.users}
                        placeholder="Choose member"
                        className="head-filter-member-fa"
                        selected=""
                    />
                    )}
                    </FormGroup>
                    <FormGroup>
                    <Label for="amount">Amount</Label>
                    <span className="d-flex align-items-center amount-field">
                        <Input 
                            type="text" 
                            name="amount" 
                            id="amount" 
                            placeholder="0.00" 
                            step="0.01" 
                            min="0.01" 
                            value={formData.amount} 
                            onChange={handleInputChange}
                            required
                        />
                        <span className="currency">{selectedCurrencyAdd}</span>
                    </span>
                    
                    </FormGroup>
                    <FormGroup>
                    <Label for="settled_on">Settlement Date (Optional)</Label>
                    <Datetime
                        value={formData.settled_on}
                        onChange={handleDateChange}
                        isValidDate={isValidDate}
                        inputProps={{
                            placeholder: 'Select settlement date',
                            className: 'form-control',
                            id: 'settled_on',
                            name: 'settled_on'
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                    />
                    </FormGroup>
                    <FormGroup>
                    <Label for="comment">Comment (Optional)</Label>
                    <Input 
                        type="textarea" 
                        name="comment" 
                        id="comment" 
                        placeholder="Add a comment..." 
                        value={formData.comment} 
                        onChange={handleInputChange}
                    />
                    </FormGroup>
                    <Button color="primary" className="btn-green" type="submit" block>Add Payment</Button>
                </Form>
                </div>
            </div>
            
            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)}>
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>
                Edit Payment
                </ModalHeader>
                <ModalBody>
                <Form onSubmit={handleSubmitEdit}>
                    <FormGroup>
                    <Label for="edit_user_id">Member</Label>
                    {(!usersLoading) && (
                        <Typeahead
                            id="user-picker"
                            labelKey="name"
                            onInputChange={handlePeopleChange}
                            onChange={handleChange}
                            options={usersData?.data.users}
                            placeholder="Choose member"
                            className="head-filter-member-fa"
                            selected={formData.selectedUser || []}
                        />
                    )}
                    </FormGroup>
                    <FormGroup>
                        <Label for="edit_amount">Amount</Label>
                        <span className="d-flex align-items-center amount-field">
                            <Input 
                                type="text" 
                                name="amount" 
                                id="edit_amount" 
                                placeholder="0.00" 
                                value={formData.amount} 
                                onChange={handleInputChange}
                                required
                            />
                            <span className="currency">{formData.currency}</span>
                        </span>
                    </FormGroup>
                    <FormGroup>
                    <Label for="edit_settled_on">Settlement Date (Optional)</Label>
                    <Datetime
                        value={formData.settled_on}
                        onChange={handleDateChange}
                        isValidDate={isValidDate}
                        inputProps={{
                            placeholder: 'Select settlement date',
                            className: 'form-control',
                            id: 'edit_settled_on',
                            name: 'settled_on'
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                    />
                    </FormGroup>
                    <FormGroup>
                    <Label for="edit_comment">Comment (Optional)</Label>
                    <Input 
                        type="textarea" 
                        name="comment" 
                        id="edit_comment" 
                        placeholder="Add a comment..." 
                        value={formData.comment} 
                        onChange={handleInputChange}
                    />
                    </FormGroup>
                </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={() => setEditModalOpen(false)}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmitEdit}>
                    Save Changes
                </Button>
                </ModalFooter>
            </Modal>
            </Container>
            <DeleteConfirmationModal
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                itemToDelete={paymentToDelete}
                onDelete={confirmDeletePayment}
                itemType="payment"
                itemName={paymentToDelete?.full_name}
                itemValue={paymentToDelete?.amount}
                itemValueLabel="Amount"
                />
        </div>
        </Row>
      </Container>
  </div>
  );
};

export default MemberSalaryHoldings;